/*

                                    _  _____  __    __   __
                                   | |  | |  / /`  ( (` ( (`
                                   |_|  |_|  \_\_, _)_) _)_)


                                          `-.` '.-'
                                       `-.    A   .-'.
                                    `-.    -./_\.-    .-'
                                        -.  /___\  .-
                                    `-.   `/__|__\'   .-'.
                                 `-.    -./.-"^"-.\.-      '
                                    `-.  / <`(o)*> \  .-'
                                 .-   .`/__`-...-'__\'   .-
                                ,...`-./_/____|____\_\.-'.,.
                                   ,-'    ,` . . ',   `-,
                               _,-' /____/____.____\____\ `-,_
                                       ,      |      .
                                      /     ,.+.,     \

   -<# ITCSS (Inverted Triangle CSS) -> (https://csswizardry.com/2018/11/itcss-and-skillshare/) #>-
  __________________________________________________________________________________________________

   \---------------------------/------------                          Generic CSS (Low specificity)
    \                         /  1) Settings (Design)                              Y
     \-----------------------/---------------- (optional Skin or Theme)            |
      \                     /    2) Tools                                          |
       \-------------------/--------------------                                   |
        \                 /      3) Generic                                        |
         \---------------/------------------------                                 |
          \             /        4) Elements                                       |
           \-----------/----------------------------                               |
            \         /          5) Objects                                        |
             \-------/--------------------------------                             |
              \     /            6) Components                                     |
               \---/------------------------------------                           |
                \ /              7) Utilities (Trumps)                             V
                 V                                                   Explicit CSS (High specificity)


  1) Settings:             Global variables, config switches.
  2) Tools:                Default mixins and functions.
  3) Generic:              Ground-zero styles (Normalize.css, resets, box-sizing).
  4) Base:                 Un-classed HTML elements (type selectors).
  5) Objects:              Cosmetic-free design patterns
  6) Components:           Designed components, chunks of UI.
  7) Utilities/Trumps:     Helpers and overrides.

*/

@import 'taxonomy/index.scss';

//  ________  ___       ________  ________  ________  ___
//  |\   ____\|\  \     |\   __  \|\   __  \|\   __  \|\  \
//  \ \  \___|\ \  \    \ \  \|\  \ \  \|\ /\ \  \|\  \ \  \
//   \ \  \  __\ \  \    \ \  \\\  \ \   __  \ \   __  \ \  \
//    \ \  \|\  \ \  \____\ \  \\\  \ \  \|\  \ \  \ \  \ \  \____
//     \ \_______\ \_______\ \_______\ \_______\ \__\ \__\ \_______\
//      \|_______|\|_______|\|_______|\|_______|\|__|\|__|\|_______|
//
// -----------------------------------------------------------------------------
// Styles applied globally. Rules should be carefully added here to avoid
// weird UI glitches. Components should remain isolated as much as possible.

html {
   @include typo(default);
   background-color: var(--c-layout--background);
}

*,
*:after,
*:before {
   box-sizing: inherit;
}

body {
   @include typo(default);
   margin: 0;
   padding: 0;

   &.no-scroll {
      overflow: hidden;
   }
}

@import 'layout/grid.scss';

abbr[title] {
   text-decoration: none;
   font-weight: inherit;
}

a {
   color: var(--c-interaction--default);
   text-decoration: none;
   cursor: pointer;
   @include typo(default);
}

li {
   @include typo(default);
}

a:hover {
   color: var(--c-interaction--default-hover);
}


.page-title {
   @include typo(page-title);
   margin: 0;

   &:not(:first-child) {
      margin-top: 6rem;
   }
}

.title {
   @include typo(title);
   margin: 0;

   &:not(:first-child) {
      margin: 6rem 0 0 0;
   }

   &+.subtitle {
      margin-top: 0.25rem;
   }
}

.subtitle {
   @include typo(subtitle);
   margin: 1.2rem 0 0 0;
}

.section-title {
   @include typo(section-title);
   margin: 0 0 0.6rem 0;
}

.title,
.subtitle {
   word-break: keep-all;
   white-space: pre-line;
}

.help {
   @include typo(help);

   margin-top: 0.5rem;
}

.quote {
   @include typo(quote);
}


.success {
   color: var(--c-notification--success-text);
}

.warning {
   color: var(--c-notification--warning-text);
}

.error {
   color: var(--c-notification--error-text);
}

.page {
   margin-bottom: 2rem;
   padding: size(container-stack);

   @include bp(phone) {
      padding: size(compact-container-stack);
   }
}

.container {
   flex-grow: 1;
   margin: 0 auto;
   max-width: 1280px;

   @include bp(phone) {
      max-width: 680px;
   }
}

.section {
   margin-top: size(section-stack);
}

.section--block {
   margin-top: size(block-stack-y);
}

.comfort {
   margin-top: size(comfort-stack-y);
   max-width: 550px;

   @include bp(phone) {
      max-width: inherit;
   }
}

.simple-list {
   padding: 0;
   margin: 0;
   list-style-type: none;

   li {
      margin-top: 0.4rem;

      @include bp(phone) {
         margin-top: 1rem;
      }
   }
}

.numbered-list {
   margin: 0;
   padding: 0;
   list-style-type: decimal;
   list-style-position: inside;

   @include bp(desktop) {
      list-style-position: outside;
   }

   li {
      margin: 0.4rem 0 0 -0.2rem;
      padding-left: 0.2rem;

      @include bp(tablet) {
         margin: 0.4rem 0 0 -0.1rem;
         padding-left: 0.1rem;
      }
      @include bp(phone) {
         margin: 1rem 0 0 0;
         padding-left: 0;
      }
   }
}
