/**
 * dialog-typography - SASS/SCSS module settings
 * @version v1.2.0
 * (c) 2019 meodai
 * @link https://github.com/meodai/dialog-typography#readme
 * @license MIT
 */
 // Typography settings
 $dialog-breakpoint-default: desktop !default;

 // Typography settings
 $dialog-typo: (
   desktop: (
     default: (
       font-size         : 1.125rem,
       font-family       : '"Helvetica Neue", Helvetica, Arial, sans-serif',
       font-weight       : normal,
       font-style        : normal,
       line-height       : 1.375,
     ),
     hero: (
       font-size         : 2.6667rem,
       font-weight       : bold,
       text-transform    : uppercase,
       line-height       : 1,
       color             : #000066,
     ),
     subhero: (
       font-size         : 1.333rem,
       font-weight       : lighter,
       text-transform    : uppercase,
       line-height       : 1.16667,
     ),
     heading: (
       font-size         : 1.6667rem,
       font-weight       : bold,
       text-transform    : uppercase,
       line-height       : 1.333,
     ),
     subheading: (
       font-size         : 1rem,
       font-weight       : lighter,
       text-transform    : uppercase,
       line-height       : 1.333,
       color             : #000066,
     ),
     title: (
       font-size         : 1rem,
       font-weight       : bold,
       line-height       : 1.222,
       color             : #000066,
     ),
   ),
   tablet: (
     default: (
       font-size         : 1rem,
     ),
     hero: (
       font-size         : 3rem,
     ),
     subhero: (
       font-size         : 1.5rem,
     ),
     heading: (
       font-size         : 1.875rem,
     ),
     subheading: (
       font-size         : 1.125rem,
     ),
     title: (
       font-size         : 1.1rem,
     ),
   ),
   phone: (
     default: (
       font-size         : 1rem,
     ),
     hero: (
       font-size         : 1.75rem,
     ),
     subhero: (
       font-size         : 1rem,
     ),
     heading: (
       font-size         : 1.5rem,
     ),
     subheading: (
       font-size         : 1rem,
     ),
     title: (
       font-size         : 1rem,
     ),
   )
 ) !default;

 $dialog-breakpoint-default: desktop !default;

 $dialog-breakpoints: (
   phone: 'max-width: 40rem',
   tablet: 'max-width: 64rem',
   desktop: 'min-width: 64.063rem',
 ) !default;

 $dialog-is-fluid: false !default;

 $dialog-fluid-breakpoint-sizes: (
   phone: 40rem,
   tablet: 64rem,
   desktop: 100rem,
 ) !default;

 // mixins
 $dialog-fluid-type-sizes: ();

 @if $dialog-is-fluid {
   $non-default-types: map-remove($dialog-typo, $dialog-breakpoint-default);
   $non-default-breakpoint-keys: map-keys($non-default-types);

   @each $type, $rules in map-get($dialog-typo, $dialog-breakpoint-default) {
     @if map-has-key($rules, 'font-size') {
       $fluid-sizes: ();
       $font-size: map-get($rules, 'font-size');
       $has-no-font-size: false;

       @each $non-default-breakpoint-name, $non-default-rules in $non-default-types {
         @if map-has-key($non-default-rules, $type) {
           $non-default-type: map-get($non-default-rules, $type);
           @if map-has-hey($non-default-type, 'font-size') {
             $non-default-font-size: map-get($non-default-type, 'font-size');
             $fluid-sizes: map-merge(
               $fluid-sizes,
               (map-get($dialog-fluid-breakpoint-sizes, $non-default-breakpoint-name): $non-default-font-size)
             );
           } @else {
             $has-no-font-size: true;
           }
         } @else {
           $has-no-font-size: true;
         }
         @if $has-no-font-size {
           $fluid-sizes: map-merge(
             $fluid-sizes,
             (map-get($dialog-fluid-breakpoint-sizes, $non-default-breakpoint-name): $font-size)
           );
         }
       }

       $fluid-sizes: map-merge(
         $fluid-sizes,
         (map-get($dialog-fluid-breakpoint-sizes, $dialog-breakpoint-default): $font-size)
       );

       $dialog-fluid-type-sizes: map-merge($dialog-fluid-type-sizes, ($type: $fluid-sizes)) !global;
     }
   }
 }

 /// poly-fluid-sizing
 /// Generate linear interpolated size values through multiple break points
 /// @param $property - A string CSS property name
 /// @param $map - A SASS map of viewport unit and size value pairs
 /// @requires function linear-interpolation
 /// @requires function map-sort
 /// @example
 ///   @include poly-fluid-sizing('font-size', (576px: 22px, 768px: 24px, 992px: 34px));
 /// @author Jake Wilson <jake.e.wilson@gmail.com>
 @mixin poly-fluid-sizing($property, $map) {
   // Get the number of provided breakpoints
   $length: length(map-keys($map));

   // Error if the number of breakpoints is < 2
   @if ($length < 2) {
     @error 'poly-fluid-sizing() $map requires at least values';
   }

   // Sort the map by viewport width (key)
   $map: map-sort($map);
   $keys: map-keys($map);

   // Minimum size
   #{$property}: map-get($map, nth($keys, 1));

   // Interpolated size through breakpoints
   @for $i from 1 through ($length - 1) {
     @media (min-width: nth($keys, $i)) {
       #{$property}: linear-interpolation((nth($keys, $i): map-get($map, nth($keys, $i)), nth($keys, ($i+1)): map-get($map, nth($keys, ($i + 1)))));
     }
   }

   // Maxmimum size
   @media (min-width: nth($keys, $length)) {
     #{$property}: map-get($map, nth($keys, $length));
   }
 }

 /// linear-interpolation
 /// Calculate the definition of a line between two points
 /// @param $map - A SASS map of viewport widths and size value pairs
 /// @returns A linear equation as a calc() function
 /// @example
 ///   font-size: linear-interpolation((320px: 18px, 768px: 26px));
 /// @author Jake Wilson <jake.e.wilson@gmail.com>
 @function linear-interpolation($map) {
   $keys: map-keys($map);
   @if (length($keys) != 2) {
     @error 'linear-interpolation() $map must be exactly 2 values';
   }
   // The slope
   $m: (map-get($map, nth($keys, 2)) - map-get($map, nth($keys, 1))) / (nth($keys, 2) - nth($keys, 1));

   // The y-intercept
   $b: map-get($map, nth($keys, 1)) - $m * nth($keys, 1);

   // Determine if the sign should be positive or negative
   $sign: '+';
   @if ($b < 0) {
     $sign: '-';
     $b: abs($b);
   }

   @return calc(#{$m*100}vw #{$sign} #{$b});
 }

 /// list-sort
 /// Sort a SASS list
 /// @param $list - A SASS list
 /// @returns A sorted SASS list
 /// @requires function list-remove
 /// @author Jake Wilson <jake.e.wilson@gmail.com>
 @function list-sort($list) {
   $sortedlist: ();
   @while length($list) > 0 {
     $value: nth($list, 1);
     @each $item in $list {
       @if $item < $value {
         $value: $item;
       }
     }
     $sortedlist: append($sortedlist, $value, 'space');
     $list: list-remove($list, index($list, $value));
   }
   @return $sortedlist;
 }

 /// map-sort
 /// Sort map by keys
 /// @param $map - A SASS map
 /// @returns A SASS map sorted by keys
 /// @requires function list-sort
 /// @author Jake Wilson <jake.e.wilson@gmail.com>
 @function map-sort($map) {
   $keys: list-sort(map-keys($map));
   $sorted-map: ();
   @each $key in $keys {
     $sorted-map: map-merge($sorted-map, ($key: map-get($map, $key)));
   }
   @return $sorted-map;
 }

 /// list-remove
 /// Remove an item from a list
 /// @param $list - A SASS list
 /// @param $index - The list index to remove
 /// @returns A SASS list
 /// @author Jake Wilson <jake.e.wilson@gmail.com>
 @function list-remove($list, $index) {
   $new-list: ();
   @for $i from 1 through length($list) {
     @if $i != $index {
       $new-list: append($new-list, nth($list, $i), 'space');
     }
   }
   @return $new-list;
 }

 @mixin bp($name) {
   @media (#{map-get($dialog-breakpoints, $name)}) {
     @content
   }
 }

 @mixin typo($name: default, $overwrites: null, $fluid: $dialog-is-fluid) {
   $type-breakpoints: map-keys($dialog-typo);
   $default-types: map-get($dialog-typo, $dialog-breakpoint-default);

   @if map-has-key($default-types, $name) {
     $breakpoint-styles: map-get($default-types, $name);
     @if $overwrites {
       $breakpoint-styles: map-merge($breakpoint-styles, $overwrites);
     }
     /// prints styles for the default breakpoint
     @each $property, $value in $breakpoint-styles {
       @if $dialog-is-fluid and ($property == 'font-size')  {
         @include poly-fluid-sizing('font-size', map-get($dialog-fluid-type-sizes, $name));
       } @else if $property == 'font-family' {
         #{$property}: unquote($value);
       } @else {
         #{$property}: $value;
       }
     }
     @if not $fluid {
       @include typoBreakpoint($type-breakpoints, $name, $default-types);
     }
   } @else {
     @warn 'no type named `#{$name}` in $dialog-typo';
   }
 }

 @mixin cssify-map($declarations) {
   @each $property, $value in $declarations {
     #{$property}: $value;
   }
 }

 // renders modifieres
 @mixin typoBreakpoint($type-breakpoints, $name, $default-types) {
   @each $breakpoint-name in $type-breakpoints {
     @if not ($breakpoint-name == $dialog-breakpoint-default) {
       @include bp($breakpoint-name) {
         $breakpoint-styles: map-get($dialog-typo, $breakpoint-name);
         @include cssify-map(map-get($breakpoint-styles, $name));
       }
     }
   }
 }

 // renders default text helper classes
 @mixin textClasses ($prefix: '.t-') {
   $default-types: map-get($dialog-typo, $dialog-breakpoint-default);
   @each $rule, $key in $default-types {
     @if not ($rule == default)  {
       #{$prefix}#{$rule} {
         @include typo($rule);
       }
     }
   }
 }