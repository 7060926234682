// selector name + width
$fractions: ('1\\/1': 1/1,
  '1\\/2': 1/2,
  '1\\/3': 1/3,
  '2\\/3': 2/3,
  '1\\/4': 1/4,
);

// needed breakpoints
$breakpoints: $dialog-breakpoints;

$defaultBreakpoint: 'desktop';

// actual mixin
@mixin cell($breakpoint-name: null) {

  @each $fraction-key,
  $fraction-value in $fractions {
    $selector: --#{$fraction-key};

    @if ($breakpoint-name) {
      $selector: --#{$fraction-key}\@#{$breakpoint-name};
    }

    &#{$selector} {
      width: calc(#{$fraction-value * 100%} - var(--size-gutter-x));
      flex: 0 0 calc(#{$fraction-value * 100%} - var(--size-gutter-x));
    }
  }
}

.grid {
  --size-gutter: var(--size-grid-gutter, 1rem);
  --size-gutter-x: var(--size-gutter);
  --size-gutter-y: var(--size-gutter);

  display: flex;
  width: calc(100% + var(--size-gutter-x));
  margin-left: calc(-1 * var(--size-gutter-x));
  margin-bottom: calc(-1 * var(--size-gutter-y));
  flex-wrap: wrap;

  &.content__block--narrow {
    margin-left: calc(8% - calc(-1 * var(--size-gutter-x)));
    width: calc(100% - var(--size-gutter-x) - 16%);

    @media (max-width: 640px) {
      width: calc(100% + var(--size-gutter-x));
      margin-left: calc(-1 * var(--size-gutter-x));
    }
  }

  &__cell {
    flex: 1 0 auto;
    margin-left: var(--size-gutter-x);
    margin-bottom: var(--size-gutter-y);
    box-sizing: border-box;

    @include cell;

    @each $breakpoint-name, $breakpoint-value in $breakpoints {
      @media (#{unquote($breakpoint-value)}) {
        @include cell($breakpoint-name: $breakpoint-name);
      }
    }

    .hipster-mode & {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        right: 0;
        height: var(--size-grid-gutter);
        transform: translateY(-100%);

        border: solid red;
        border-width: 2px 0;
        background: linear-gradient(90deg, transparent 50%, red calc(50% + 1px), transparent calc(50% + 3px));
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 2px;
        bottom: 0;
        width: var(--size-grid-gutter);
        transform: translateX(-100%);

        border: solid red;
        border-width: 0 2px;
        background: linear-gradient(0deg, transparent 50%, red calc(50% + 1px), transparent calc(50% + 3px));
      }

      &:first-child {
        &::after {
          display: none;
        }
      }
    }
  }

  &--noGutter {
    --size-gutter: 0rem;
    --size-gutter-x: var(--size-gutter);
    --size-gutter-y: var(--size-gutter);
  }

  &--end {
    align-items: flex-end;
  }

  &--stretch {
    align-items: stretch;
  }

  &--center {
    align-items: center;
  }

  &--related {
    margin-top: var(--size-bezel);
  }
}