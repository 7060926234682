$sizes: ((
  'value': 0,
  'names': ('border-radius')
), (
  'value': 0.1rem,
  'names': ('line', 'input-border-width', 'table-border-width', 'table-row-border-width', 'row-border-width', 'border-width')
), (
  'value': 0.4rem,
  'names': ('inline', 'icon-inline')
), (
  'value': 1.2rem,
  'names': ('input-bezel--y', 'tag-gutter', 'icon-bezel', 'compact-bezel--y', 'nav-bezel', 'notification-bezel', 'hspace-bezel', 'list-item-bezel')
), (
  'value': 0.5rem,
  'names': ('input-bezel--x', 'label-inline', 'button-icon-inline', 'input-icon-bezel--x', 'form-futter--narrow', 'symbol-inline', 'stack-x')
),  (
  'value': 1.1rem,
  'names': ('card-compact-body-bezel--y', 'button-bezel', 'header-stack-y', 'compact-container-stack')
), (
  'value': 1.4rem,
  'names': ('form-gutter', 'grid-gutter', 'card-aside-bezel--y', 'section-padding-x'),
  'isBase': 'true'
), (
  'value': 1.6rem,
  'names': ('card-bezel', 'card-body-bezel--y', 'card-section-stack', 'dialogue-bezel--x', 'dialogue-bezel--y')
), (
  'value': 4rem,
  'names': ('content-bezel', 'card-stack', 'table-row-stack-y')
), (
  'value': 2rem,
  'names': ('input', 'textarea', 'comfort-stack-y', 'block-stack-y', 'container-stack')
), (
  'value': 2.5rem,
  'names': ('select')
), (
  'value': 2.25rem,
  'names': ('card-body-bezel--y')
), (
  'value': 2.5rem,
  'names': ('card-body-bezel--x')
), (
  'value': 6rem,
  'names': ('section-stack')
));
