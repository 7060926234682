$dialog-typo: (
  'desktop': (
    'default': (
      'font-size': 1.2rem,
      'font-family': "'Inter', sans-serif",
      'font-weight': 300,
      'font-style': normal,
      'line-height': 1.4,
      'color': var(--c-text--default)
    ),
    'tag': (
      'color': var(--c-text--default)
    ),
    'button': (
      'color': var(--c-text--default)
    ),
    'button--large': (
      'font-size': 1.2rem,
    ),
    'button--small': (
      'font-size': 1rem,
    ),
    'link': (
      'color': var(--c-interaction--default)
    ),
    'meta': (
      'font-size': 1.2rem,
      'line-height': 1.3,
      'color': var(--c-text--weak)
    ),
    'page-title': (
      'font-family': "'Muli', sans-serif",
      'font-size': 3.6rem,
      'line-height': 1.1,
      'font-weight': 900
    ),
    'title': (
      'font-family': "'Muli', sans-serif",
      'font-size': 2.4rem,
      'line-height': 1.1,
      'font-weight': 900
    ),
    'subtitle': (
      'font-family': "'Inter', sans-serif",
      'font-size': 1.4rem,
      'line-height': 1.2,
      'font-weight': 200
    ),
    'section-title': (
      'font-family': "'Muli', sans-serif",
      'font-size': 1.2rem,
      'line-height': 1.2,
      'font-weight': 400
    ),
    'modal-title': (
      'font-family': "'Muli', sans-serif",
      'font-size': 1.5rem,
      'line-height': 1.3,
      'font-weight': 700,
      'text-align': center
    ),
    'help': (
      'font-size': 0.75rem
    ),
    'quote': (
      'font-size': 1.15rem,
      'font-style': italic,
      'font-weight': 200,
    ),
    'label': (
      'font-size': 1rem,
      'font-weight': 700,
    ),
    'form': (
      'font-size': 1rem,
    ),
    'sidenav-link': (
      'font-size': 1.4rem,
    ),
    'code': (
      'font-family': '"Fira code", "Fira Mono", monospace'
    )
  ),
  'tablet': (
    'default': (
      'font-size': 1.1rem,
      'line-height': 1.5,
    )
  ),
  'phone': (
    'default': (
      'font-size': 1rem,
      'line-height': 1.5,
    )
  )
);